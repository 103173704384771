import "../App.css";
const Review = () => {
  return (
    <div
     className=" bg__review">
  </div>  
  ) 
};

export default Review;
